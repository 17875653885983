<template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <div v-show="checkSpinner">
        <div class="row">
          <div class="col"></div>
          <div class="col"><SpinnerComponent /></div>
          <div class="col"></div>
        </div>
      </div>
      <div v-show="checkSpinnerFalse">
        <article>
          <div class="container-fluid">
            <br />
            <h1>Lista distribuidores</h1>
            <br />

            <div class="row">
              <!--BUTTONS DE IMPORT E NOVO CADASTRO-->
              <div class="col"></div>
              <div class="col">
                <router-link :to="{ name: 'cadastrodistribuidores' }"
                  ><button class="buttonNew">Novo</button></router-link
                >
              </div>
              <div class="col">
                <button class="buttonImport" @click="importar()">
                  Importar
                </button>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col">
                <div class="form-group input-group">
                  <input
                    name="consulta"
                    id="filterbox"
                    placeholder="Pesquisar"
                    type="search"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col"></div>
              <div class="col">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="id do distribuidor"
                    v-model="detalis"
                  />
                  <router-link
                    :to="{ name: 'detalhes', params: { id: detalis } }"
                    ><button class="buttonDatails">
                      Detalhes
                    </button></router-link
                  >
                </div>
              </div>
              <div class="col">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="id do distribuidor"
                    v-model="editar"
                  />
                  <router-link
                    :to="{ name: 'editarDistribuidor', params: { id: editar } }"
                    ><button class="buttonEdit">Editar</button></router-link
                  >
                </div>
              </div>
            </div>

            <br />

            <div class="table-responsive">
              <table class="table" id="tabela">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Tipo</th>
                    <th scope="col" @click="sortDistribuidores('nome')">
                      Nome
                    </th>
                    <th scope="col">Usuario Maxinivel</th>
                    <th scope="col">Cidade</th>
                    <th scope="col">UF</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Data Importação</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <td>
                    <button class="delButton" @click="destroyDistribuidor(distribuidor.id_distribuidor)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="del" viewBox="0 0 16 16">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                      </svg>
                    </button>          
                <td>
                <td>
              <router-link :to="{name: 'detalhes', params: {id: distribuidor.id_distribuidor} }"><button class="buttonDatails">Detalhes</button></router-link>
              </td>
              <td>
              <router-link :to="{name: 'editarDistribuidor', params: {id: distribuidor.id_distribuidor}}"><button class="buttonEdit">Editar</button></router-link>
              </td>
              </tr>  -->
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>
    </div>
    <link
      rel="stylesheet"
      type="text/css"
      href="//cdn.datatables.net/1.10.13/css/jquery.dataTables.css"
    />
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"
    />
  </DashboardComponent>
</template>


<script>
import axios from "axios";
//Destruir  distribuidor
const Swal = require("sweetalert2");
//import Distribuidor from "../../../services/Distribuidor";
import { http } from "../../../http/index";
import SpinnerComponent from "../Dashboard/components/Spinner.vue";

//import { mapActions, mapState} from 'vuex';
import DashboardComponent from "../Dashboard/Dashboard.vue";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "ListaDistribuidoresComponent",
  components: {
    DashboardComponent,
    SpinnerComponent,
  },
  data() {
    return {
      checkSpinner: true,
      checkSpinnerFalse: false,
      maxinivelDistri: {
        distribuidores: [],
      },
      tokenMaxin: null,
      filterDistri: [],
      distriTo: [],
      detalis: "",
      editar: "",
      cont: "",

      validation: {
        client_id: "ALLINCRMW_8ebae0074",
        client_secret: "6c441e81fa1b80e80fb7128690ae1844f7e82487",
        grant_type: "client_credentials",
      },
    };
  },
  computed: {
    // ...mapState("distribuidores", ["distribuidores"]),
  },
  methods: {
    //...mapActions("distribuidores", ["getDistribuidores","inativeDistribuidor"]),

    filterDistribuidores() {
      
      this.distriTo.forEach((distribuidor) => {
        while (!distribuidor.id_maxinivel) {
          distribuidor.id_maxinivel = "Não informado";
        }
        if (distribuidor.id_maxinivel.includes("excluido")) {
          distribuidor.id_maxinivel = "Excluido";
        }
        if (!distribuidor.celular) {
          distribuidor.celular = "Não informado";
        }
        if (!distribuidor.nome) {
          distribuidor.nome = "Não informado";
        }
        if (!distribuidor.tipo) {
          distribuidor.tipo = "Indeterminavel";
        }
        if (!distribuidor.cidade) {
          distribuidor.cidade = {
            descricao: "Não informado",
            uf: "Não informado",
          };
        }

        let corte = distribuidor.created_at.split("T");
        let divisao = corte[0].split("-");
        distribuidor.created_at = `${divisao[2]}-${divisao[1]}-${divisao[0]} `;
        

        this.filterDistri.push(distribuidor);
      });
      //console.log(this.filterDistri);

      this.teble();
    },
    //Importa dados dos distribuidores pegos na api da maxinivel e manda para a api da ECO
    localImport(data) {
      http
        .post("import", data)
        .then((response) => {
          if (response.status === 200) {
            // this.$router.push({ name: "listadistribuidores" });
           // console.log("ok")
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    //Função busca dados na api da maxinivel
    importar() {
      this.checkSpinner = true;
      this.checkSpinnerFalse = false;

      this.tokenMaxinivel();

      Swal.fire({
        title: "Atenção",
        text: "Vocẽ deseja realmente importar os distribuidores",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "rgb(0, 255, 30)",
        cancelButtonColor: "rgb(255, 42, 0)",
        confirmButtonText: "Sim, desejo importar",
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.cont = (this.filterDistri.length / 100) + 2;
            //console.log(this.cont);

            for (let i = 1; i <= this.cont; i++) {
              this.tokenMaxin = localStorage.getItem("tokenMax");
              axios
                .get(`https://allinbrasil.com.br/api/v1/clientes?page=${i}`, {
                  headers: {
                    Authorization: `Bearer ${this.tokenMaxin}`,
                  },
                })
                .then((response) => {
                  if (response.status === 200) {
                    this.maxinivelDistri.distribuidores =
                      response.data.clientes;
                    this.localImport(this.maxinivelDistri);
                    //console.log(response.data);
                  }
                })
                .catch((error) => console.log(error));
            }
            this.checkSpinner = false;
            this.checkSpinnerFalse = true;
          }
        })
        .finally(() => {
          this.$fire({
            title: "Sucesso",
            text: "Os Distribuidores foram importados com sucesso, espere alguns momentos e atualize a página!",
            type: "success",
            timer: 5000,
          });
        });
    },
    //Função busca token de acesso para a Api da maxinivel
    tokenMaxinivel() {
      axios
        .post("https://allinbrasil.com.br/api/v1/auth/token", this.validation)
        .then((response) => {
          if (response.status == 200) {
            localStorage.setItem("tokenMax", response.data.access_token);
          }
        })
        .catch((erro) => console.log(erro))
        .finally(() => {});
    },

    // inativeDistribuidor(id_distribuidor) {
    //   Swal.fire({
    //     title: "Atenção",
    //     text: "Vocẽ deseja realmente inativar esse DISTRIBUIDOR",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Sim, desejo inativar",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       Distribuidor.inative(id_distribuidor)
    //         .then((response) => {
    //           this.$router.push({ name: "listadistribuidores" });
    //           if (response.status == 200) {
    //             this.getDistribuidores(),
    //               this.$fire({
    //                 title: "Sucesso",
    //                 text: "Distribuidor inativado com sucesso",
    //                 type: "success",
    //                 timer: 5000,
    //               }).then(() => {});
    //           } else {
    //             alert("algo deu errado, tente novamente");
    //           }
    //         })
    //         .catch(console.error());
    //     }
    //   });
    // },


    //Função busca tabelas na api
    getUser() {
      http.get("distribuidores").then((response) => {
        this.distriTo = response.data;
        this.filterDistribuidores();
      });
    },

    // função monta a tabela
    teble() {
      this.checkSpinner = true;
      this.checkSpinnerFalse = false;
      $.noConflict();
      var table = $("#tabela").DataTable({
        stateSave: false,
        bDestroy: true,
        scrollCollapse: true,
        data: this.filterDistri,
        columns: [
          { data: "id_distribuidor" },
          { data: "tipo" },
          { data: "nome" },
          { data: "id_maxinivel" },
          { data: "cidade.descricao" },
          { data: "cidade.uf" },
          { data: "celular" },
          { data: "created_at" },
        ],
        processing: true,

        language: {
          url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Portuguese-Brasil.json",
        },
        //Tira a funçao de ordenar de uma coluna
        columnDefs: [
          { orderable: false, targets: 5 },
          { orderable: false, targets: 6 },
          { orderable: false, targets: 7 },
          { targets: -1 },
          { defaultContent: "<button>Click!</button>" },
        ],
        order: [[1, "asc"]],
        //tira o input padrao de busca do dataTable
        dom: "lrtip",
      });
      //Coloca a funçao de filtro (busca) em um input personalizado
      $("#filterbox").on("keyup", function () {
        table.search(this.value).draw();
      });

      this.checkSpinner = false;
      this.checkSpinnerFalse = true;
    },
  },
  created() {
    //this.getDistribuidores();
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style scoped>
.content-pages {
  padding: 1px;
}
article {
  background-color: #1f1f1f;
  height: 100%;
}
e {
  visibility: hidden;
}
h1 {
  text-align: center;
}
input[type="text"] {
  border: 1px solid black;
  border-radius: 4px;
}
thead {
  font-size: 18px;
  text-align: center;
  background-color: rgb(156, 153, 153);
}
tbody {
  font-size: 17px;
  text-align: center;
  color: white;
}
td {
  background-color: #1f1f1f;
  color: white;
}
.btn btn-success {
  background-color: orange;
}
.buttonNew {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: #ffce40;
  border: none;
  color: white;
  font-weight: bold;
}
.buttonImport {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(39 164 227);
  border: none;
  font-weight: bold;
  color: white;
}
.buttonEdit {
  font-weight: bold;
  height: 40px;
  width: 90px;
  border-radius: 4px;
  background-color: rgb(39 164 227);
  border: none;
  color: rgb(255 255 255);
}
.buttonDatails {
  height: 40px;
  width: 90px;
  border-radius: 4px;
  font-weight: bold;
  background-color: rgb(255, 128, 0);
  border: none;
  color: white;
}

@media (max-width: 1100px) {
  h1 {
    text-align: center;
    font-size: 25px;
  }
  .buttonEdit {
    height: 40px;
    width: 90px;
    border-radius: 4px;
    border: none;
    font-size: 12px;
  }
  .buttonDatails {
    height: 40px;
    width: 90px;
    border-radius: 4px;
    border: none;
    font-size: 12px;
  }
}
.delButton {
  border: none !important;
  background-color: #1f1f1f !important;
}
.del {
  color: red;
}
</style>