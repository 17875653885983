<template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <div v-show="checkSpinner">
        <div class="row">
          <div class="col"></div>
          <div class="col"><SpinnerComponent /></div>
          <div class="col"></div>
        </div>
      </div>
      <div v-show="checkSpinnerFalse">
        <article>
          <div class="container-fluid">
            <h1>Detalhes do Distribuidor</h1>
            <div class="table-responsive">
              <table class="table">
                <thead class="theadOne">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Nome Fantasia</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Usuario Maxinivel</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{{ nome }}</td>
                    <td>{{ nome_fantasia }}</td>
                    <td>{{ celular }}</td>
                    <td>{{ id_maxinivel }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead class="theadTwo">
                  <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Razão Social</th>
                    <th scope="col">CPF/CNPJ</th>
                    <th scope="col">IE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ email }}</td>
                    <td>{{ razao_social }}</td>
                    <td>{{ cnpj_cpf }}</td>
                    <td>{{ ie }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive">
              <table class="table">
                <thead class="theadThree">
                  <tr>
                    <th scope="col">Logradouro</th>
                    <th scope="col">Numero</th>
                    <th scope="col">Bairro</th>
                    <th scope="col">Cidade</th>
                    <th scope="col">UF</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ logradouro }}</td>
                    <td>{{ numero }}</td>
                    <td>{{ bairro }}</td>
                    <td>{{ mCidade }}</td>
                    <td>{{ mUf }}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h1 class="cli">Clientes do Distribuidor</h1>
            <div class="table-responsive">
              <table class="table">
                <thead class="theadCli">
                  <tr>
                    <th scope="col">Cliente</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">UF</th>
                    <th scope="col">Cidade</th>
                    <th scope="col">Distribuidor Afiliado</th>
                    <th scope="col">ID Maxinivel Distribuidor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cliente in cliFiltrados" :key="cliente.id_cliente">
                    <td>{{ cliente.nome }}</td>
                    <td>{{ cliente.celular }}</td>
                    <td>{{ cliente.uf }}</td>
                    <td>{{ cliente.cidade["descricao"] }}</td>
                    <td>{{ cliente.distribuidor["nome"] }}</td>
                    <td>{{ cliente.distribuidor["id_maxinivel"] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>
    </div>
  </DashboardComponent>
</template>

<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
import SpinnerComponent from "../Dashboard/components/Spinner.vue";
import { http } from "../../../http/index";
export default {
  name: "DetailsComponent",
  components: {
    DashboardComponent,
    SpinnerComponent,
  },
  data() {
    return {
      checkSpinner: true,
      checkSpinnerFalse: false,

      nome: "",
      nome_fantasia: "",
      celular: "",
      id_maxinivel: "",
      email: "",
      razao_social: "",
      cnpj_cpf: "",
      ie: "",
      logradouro: "",
      numero: "",
      bairro: "",
      mCidade: "",
      mUf: "",

      teste: "Nao informado",

      payload: {
        id_distribuidor: this.$route.params.id,
      },
      distri: {},
      clientes: [],
      cliFiltrados: [],
    };
  },

  methods: {
    //Função filtra e corrige dados do distribuidor de possiveis tados nulos

    filterDistribuidor() {
      this.checkSpinner = true;
      this.checkSpinnerFalse = false;

      (this.nome = this.distri.nome),
        (this.nome_fantasia = this.distri.nome_fantasia),
        (this.celular = this.distri.celular),
        (this.id_maxinivel = this.distri.id_maxinivel),
        (this.email = this.distri.email),
        (this.razao_social = this.distri.razao_social),
        (this.cnpj_cpf = this.distri.cnpj_cpf),
        (this.ie = this.distri.ie),
        (this.logradouro = this.distri.logradouro),
        (this.numero = this.distri.numero),
        (this.bairro = this.distri.bairro),
        (this.mUf = this.distri.uf),
        (this.mCidade = this.distri.cidade["descricao"]);

      if (this.nome == null) {
        this.nome = "Não Informado";
      }
      if (this.nome_fantasia == null) {
        this.nome_fantasia = "Não Informado";
      }
      if (this.celular == null) {
        this.celular = "Não Informado";
      }
      if (this.id_maxinivel == null) {
        this.id_maxinivel = "Não Informado";
      }
      if (this.email == null) {
        this.email = "Não Informado";
      }
      if (this.razao_social == null) {
        this.razao_social = "Não Informado";
      }
      if (this.cnpj_cpf == null) {
        this.cnpj_cpf = "Não Informado";
      }
      if (this.ie == null) {
        this.ie = "Não Informado";
      }
      if (this.logradouro == null) {
        this.logradouro = "Não Informado";
      }
      if (this.numero == null) {
        this.numero = "Não Informado";
      }
      if (this.bairro == null) {
        this.bairro = "Não Informado";
      }
      if (this.mCidade == null) {
        this.mUf = "Não Informado";
      }
      if (this.mUf == null) {
        this.mUf = "Não Informado";
      }

      this.checkSpinner = false;
      this.checkSpinnerFalse = true;
    },

    //Função filtra e corrige dados de clientes do distribuidor de possiveis tados nulos

    filterClients() {
      this.clientes.forEach((cli) => {
        if (cli.nome == null) {
          cli.nome = "Não Informado";
        }
        if (cli.celular == null) {
          cli.celular = "Não Informado";
        }
        if (cli.cidade == null) {
          cli.cidade = "Não informado";
        }
        if (cli.uf == null) {
          cli.uf = "Não informado";
        }
        if (cli.distribuidor["nome"] == null) {
          cli.distribuidor = {
            nome: "Não informado",
          };
        }
        if (cli.distribuidor["id_maxinivel"] == null) {
          cli.distribuidor = {
            id_maxinivel: "Não informado",
          };
        }
        this.cliFiltrados.push(cli);
      });
    },

    // Busca na api os dados do distribuidor pelo id do mesmo
    getUser() {
      http
        .post("distribuidores/id", this.payload)
        .then((response) => {
          this.distri = response.data;
        })
        .catch((erro) => {
          console.log(erro);
        })
        .finally(() => {
          this.filterDistribuidor();
        });
    },

    // Busca na api os dados dos clientes dos distribuidor pelo id do mesmo
    getClient() {
      var distribuidor = this.$route.params.id;
      http
        .get(`aclientes/distribuidor=${distribuidor}`)
        .then((res) => {
          this.clientes = res.data;
        })
        .catch((erro) => {
          console.log(erro);
        })
        .finally(() => {
          this.filterClients();
        });
    },
  },

  created() {
    this.getUser();
    this.getClient();
  },
};
</script>

<style scoped>
article {
  background-color: #1f1f1f;
}
.content-pages {
  padding: 1px;
}

.theadOne {
  background-color: rgb(183, 180, 180);
  box-shadow: 2px 10px 20px 2px rgba(0, 0, 0, 0.3);
  font-size: 18px;
}
.theadTwo {
  background-color: rgb(183, 180, 180);
  box-shadow: 2px 10px 20px 2px rgba(0, 0, 0, 0.3);
  font-size: 18px;
}
.theadThree {
  background-color: rgb(183, 180, 180);
  box-shadow: 2px 10px 20px 2px rgba(0, 0, 0, 0.3);

  font-size: 18px;
}
.theadCli {
  background-color: rgb(255, 128, 0);
  box-shadow: 2px 10px 20px 2px rgba(0, 0, 0, 0.3);
  font-size: 18px;
}
.cli {
  color: rgb(255, 128, 0);
}
td {
  color: white;
}

h1 {
  text-align: center;
  color: rgb(144, 144, 144);
}
tbody {
  font-size: 18px;
  text-align: center;
}
thead {
  text-align: center;
}

@media (max-width: 600px) {
  h1 {
    font-size: 20px;
  }
}
</style>