<template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <article>
        <div class="container-fluid">
          <br />
          <div class="row">
            <div class="col">
              <h1>Cadastro Distribuidor</h1>
            </div>
          </div>
          <form @submit.prevent="salvar" id="form">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="col-md-2 control-label" for="Nome"
                    >Nome Completo
                  </label>
                  <div class="col-md-12">
                    <input
                      id="Nome"
                      name="Nome"
                      placeholder=""
                      class="form-control input-md"
                      required
                      v-model="distribuidor.nome"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="col-md-2 control-label" for="Nome"
                  >Usuario Maxinivel</label
                >
                <div class="col-md-12">
                  <input
                    id="userMaxi"
                    name="userMaxi"
                    placeholder=""
                    class="form-control input-md"
                    required
                    v-model="distribuidor.id_maxinivel"
                    type="text"
                  />
                </div>
              </div>

              <div class="form-group">
                <label class="col-md-2 control-label" for="Nome"
                  >Nome Fantasia</label
                >
                <div class="col-md-12">
                  <input
                    id="nomeFant"
                    name="nomeFant"
                    placeholder=""
                    class="form-control input-md"
                    v-model="distribuidor.nome_fantasia"
                    type="text"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-2 control-label" for="Nome"
                  >Razão Social</label
                >
                <div class="col-md-12">
                  <input
                    id="social"
                    name="social"
                    placeholder=""
                    class="form-control input-md"
                    v-model="distribuidor.razao_social"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div style="" class="row">
              <div class="col">
                <label>
                  <input
                    type="radio"
                    class="radiocpf"
                    id="cpf-example"
                    value="###.###.###-##"
                    v-model="dinamicMask"
                  />
                  <span> Pessoa Física <a> * </a></span>
                </label>
                <label>
                  <input
                    type="radio"
                    class="radiocpf"
                    id="cnpj-example"
                    value="##.###.###/####-##"
                    v-model="dinamicMask"
                  />
                  <span> Pessoa Jurídica </span>
                </label>
                <br />
                <input
                  type="text"
                  class="form-control input-md"
                  v-mask="dinamicMask"
                  v-model="distribuidor.cnpj_cpf"
                />
              </div>
              {{ distribuidor.tipo }}

              <div class="col">
                <div class="form-group">
                  <label class="col-md-2 control-label" for="Nome">IE</label>
                  <input
                    id="ie"
                    name="ie"
                    placeholder=""
                    class="form-control input-md"
                    v-model="distribuidor.ie"
                    type="text"
                  />
                </div>
              </div>
              <div class="w-100"></div>
              <div class="col">
                <div class="form-group">
                  <label class="col-md-2 control-label" for="Nome"
                    >Logradouro</label
                  >
                  <input
                    id="lagradouro"
                    name="lagradouro"
                    placeholder=""
                    class="form-control input-md"
                    v-model="distribuidor.logradouro"
                    type="text"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="col-md-2 control-label" for="Nome"
                    >Numero</label
                  >
                  <input
                    id="numero"
                    name="numero"
                    placeholder=""
                    class="form-control input-md"
                    v-model="distribuidor.numero"
                    type="text"
                  />
                </div>
              </div>
              <div class="w-100"></div>
              <div class="col">
                <div class="form-group">
                  <label class="col-md-2 control-label" for="Nome"
                    >Bairro</label
                  >
                  <input
                    id="bairro"
                    name="bairro"
                    placeholder=""
                    class="form-control input-md"
                    v-model="distribuidor.bairro"
                    type="text"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="col-md-2 control-label" for="Nome">UF</label>
                  <select
                    id="inputState"
                    class="form-control"
                    required
                    v-model="distribuidor.uf"
                    @change="getCity()"
                  >
                    <option selected>Selecione o estado</option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espirito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="rs">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </div>
              </div>

              <div class="w-100"></div>
              <div class="col">
                <div class="form-group">
                  <label class="col-md-2 control-label" for="Nome">CEP</label>
                  <input
                    id="cep"
                    name="cep"
                    placeholder=""
                    class="form-control input-md"
                    v-model="distribuidor.cep"
                    type="text"
                    v-mask="'#####-###'"
                  />
                </div>
              </div>

              <div class="col">
                <div class="form-group">
                  <label class="col-md-2 control-label" for="Nome"
                    >Cidade</label
                  >
                  <select
                    id="inputState"
                    class="form-control"
                    v-model="distribuidor.id_cidade"
                  >
                    <option
                      v-for="cidade in cidades"
                      :key="cidade.id_cidade"
                      :value="cidade.id_cidade"
                    >
                      {{ cidade.descricao }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-2 control-label" for="Nome">Email</label>
              <div class="col-md-12">
                <input
                  id="email"
                  name="email"
                  placeholder=""
                  class="form-control input-md"
                  v-model="distribuidor.email"
                  type="email"
                />
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-2 control-label" for="Nome">Telefone</label>
              <div class="col-md-12">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  class="form-control input-md"
                  v-model="distribuidor.celular"
                  v-mask="'(##) #####-####'"
                />
              </div>
            </div>
            <!-- Submit button -->
            <br />
            <div class="container-fluid">
              <div style="" class="row">
                <div class="col">
                  <button type="submit" class="buttonSave">Salvar</button>
                </div>
                <div class="col">
                  <router-link :to="{ name: 'listadistribuidores' }"
                    ><button class="buttonCancel" role="button">
                      Voltar
                    </button></router-link
                  >
                </div>
                <div class="col"></div>
              </div>
            </div>
          </form>
          <br /><br />
        </div>
      </article>
    </div>
  </DashboardComponent>
</template>

<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
//import distribuidor from "../../../services/Distribuidor";
//import cidade from "../../../services/Cidade";
import {http} from "../../../http/index"

//const Swal = require('sweetalert2')

export default {
  name: "DistribuidoresComponent",
  components: {
    DashboardComponent,
  },
  data() {
    return {
      dinamicMask: " ",

      cidades: [],
      uf: "",
      distribuidor: {
        id_distribuidor: "",
        nome: "",
        id_maxinivel: "",
        email: "",
        nome_fantasia: "",
        razao_social: "",
        cnpj_cpf: "",
        tipo: "",
        ie: "",
        logradouro: "",
        numero: "",
        bairro: "",
        cidade: "",
        cep: "",
        uf: "",
      },
       getCitys: {
         uf: ""
       },
    };
  },
  created() {
    //chama a cidade pelo uf
    //this.getCity;
  },
  methods: {
    //chama lista de cidade pelo uf
    getCity() {

      this.cidades = [];

      this.getCitys.uf = this.distribuidor.uf;

      http.get(`cidades/${this.getCitys.uf}`).then((response) => {
        this.cidades = response.data;
      }).catch((erro)=>{
        console.log(erro);
        this.$fire({
            title: "Opss...",
            text: "Algo deu errado, por favor tente novamente",
            type: "error",
            timer: 5000,
          })
      }).finally(()=>{})
    },

    limpar() {
      document.getElementById("form").reset();
    },
    //Cadastro de distribuidor
    salvar() {
      if (this.dinamicMask.length == 14) {
        this.distribuidor.tipo = "Pessoa Física";
      } else {
        this.distribuidor.tipo = "Pessoa Juridica";
      }
      // distribuidor
      // .salvar(this.distribuidor)

        http.post('distribuidores' , this.distribuidor)
        .then((response) => {
          if (response.status == 201 || response.status == 200) {
            this.$router.push({ name: "listadistribuidores" });
            this.$fire({
              title: "Sucesso",
              text: "Distribuidor cadastrado com sucesso",
              type: "success",
              timer: 5000,
            }).then((r) => {
              console.log(r.value);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$fire({
            title: "Opss...",
            text: "Algo deu errado, por favor tente novamente",
            type: "error",
            timer: 5000,
          }).then((r) => {
            console.log(r.value);
          });
        });
    },
  },
};
</script>

<style scoped>
.content-pages {
  padding: 1px;
}

article {
   background-color: #1f1f1f;
  height: 100%;
  display: flex;
}
h1 {
  text-align: center;
}
form {
  background-color: #ffffff38;
  font-weight: bold;
}
.buttonSave {
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: #ffce40;
  border: none;
}
.buttonCancel {
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(39 164 227);
  border: none;
}
.radiocpf {
  padding: 10px;
}
</style>