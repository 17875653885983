import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import VueSimpleAlert from "vue-simple-alert"
import router from '../src/router/router'

import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueMask from 'v-mask';
//import axios from 'axios';

Vue.config.productionTip = false

Vue.use(Vuex);
Vue.use(VueMask);
Vue.use(VueSimpleAlert);





new Vue({
    router,
    render: h => h(App),
}).$mount('#app')