<template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <div v-show="checkSpinner">
        <div class="row">
          <div class="col"></div>
          <div class="col"><SpinnerComponent /></div>
          <div class="col"></div>
        </div>
      </div>
      <div v-show="checkSpinnerFalse">
        <div class="container-fluid">
          <br /><br />
          <div class="row">
            <div class="col"></div>
            <div class="col"></div>
            <div class="col">
              <button
                type="button"
                class="buttonImprimir"
                @click="generatePDF()"
              >
                Imprimir
              </button>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col">
              <div class="form-group input-group">
                <input
                  name="consulta"
                  id="filterbox"
                  placeholder="Pesquisar"
                  type="search"
                  class="form-control"
                />
              </div>
            </div>
          </div>
          <br />
          <h1>Lista geral de Clientes</h1>
          <div class="table-responsive">
            <table class="table" id="geral">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Cliente</th>
                  <th scope="col">Telefone</th>
                  <th scope="col">UF</th>
                  <th scope="col">Cidade</th>
                  <th scope="col">Distribuidor Afiliado</th>
                  <th scope="col">ID Maxinivel Distribuidor</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </DashboardComponent>
</template>


<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
import SpinnerComponent from "../Dashboard/components/Spinner.vue";
import { http } from "../../../http/index";
const { jsPDF } = require("jspdf");
import "jspdf-autotable";
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
export default {
  name: "ClientesTotalComponenet",
  components: {
    DashboardComponent,
    SpinnerComponent,
  },

  data() {
    return {
      checkSpinner: true,
      checkSpinnerFalse: false,
      titulo: "Lista geral de clientes",
      filterClie: [],
      clientsFilt: [],
    };
  },


  created() {
    this.getUser();
  },
  methods: {
   
    //função que jerra uma lista em pdf com os dados mostrados no momento na pagina.
    generatePDF() {
      const doc = new jsPDF("p", "pt", "a4");
      doc.text(this.titulo, 15, 15);
      doc.autoTable({ html: "#geral" });
      doc.save("Lista_Clientes_Total.pdf");
    },

    //Função que cria a tabela com os dados
    table() {
      this.checkSpinner = true;
      this.checkSpinnerFalse = false;

      $.noConflict();
      var table = $("#geral").DataTable({
        stateSave: false,
        bDestroy: true,
        scrollCollapse: true,
        data: this.filterClie,
        columns: [
          { data: "nome" },
          { data: "celular" },
          { data: "cidade.uf" },
          { data: "cidade.descricao" },
          { data: "distribuidor.nome" },
          { data: "distribuidor.id_maxinivel" },
        ],
        processing: true,
        language: {
          url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Portuguese-Brasil.json",
          emptyMessage: "",
        },
        //Tira a funçao de ordenar de uma coluna
        columnDefs: [{ orderable: false, targets: 1 }],
        order: [[1, "asc"]],
        //tira o input padrao de busca do dataTable
        dom: "lrtip",
      });
      //Coloca a funçao de filtro (busca) em um input personalizado
      $("#filterbox").on("keyup", function () {
        table.search(this.value).draw();
      });
      this.checkSpinner = false;
      this.checkSpinnerFalse = true;
    },

      //Função que filtra e corrige possiveis dados nulos dos clientes
    filterClientes() {
      this.clientsFilt.forEach((cliente) => {
        while (!cliente.nome) {
          cliente.nome = "Não informado";
        }
        if (cliente.nome.includes("excluido")) {
          cliente.nome = "Excluido";
        }
        if (!cliente.celular) {
          cliente.celular = "Não informado";
        }
        if (!cliente.cidade) {
          cliente.cidade = {
            descricao: "Não informado",
            uf: "Não informado",
          };
        }
        this.filterClie.push(cliente);
      });
      this.table();
    },

      //Função busca dados(GET) de clientes finais na Api
    getUser() {
      http.get("clientes").then((response) => {
        this.clientsFilt = response.data;
        console.log(this.clientsFilt);
        this.filterClientes();
      });
    },
  },
};
</script>
<style scoped>
.content-pages {
  padding: 1px;
}

article {
  background-color: #1f1f1f;
  height: 100%;
}
h1 {
  text-align: center;
}
td {
  background-color: #1f1f1f;
  color: white;
}

thead {
  font-size: 18px;
  text-align: center;
  background-color: rgb(156, 153, 153);
}
tbody {
  font-size: 17px;
  text-align: center;
}
.buttonImprimir {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(67, 192, 255);
  color: white;
  border: none;
}
select {
  height: 35px;
  width: 100%;
}
</style>

