<template v-if="distribuidor">
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <article>
        <div class="container-fluid">
          <br />
          <div class="row">
            <div class="col">
              <h1>Editar Distribuidor</h1>
            </div>
          </div>
          <form @submit.prevent="salvar(distribuidor)" id="form">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label class="col-md-2 control-label" for="Nome"
                    >Nome Completo</label
                  >
                  <div class="col-md-12">
                    <input
                      id="Nome"
                      name="Nome"
                      class="form-control input-md"
                      type="text"
                      v-model="distribuidor.nome"
                    />
                  </div>
                </div>
                <!-- {{ updateDistribuidor.nome }} -->
              </div>

              <div class="form-group">
                <label class="col-md-2 control-label" for="Nome"
                  >Usuario Maxinivel</label
                >
                <div class="col-md-12">
                  <input
                    id="userMaxi"
                    name="userMaxi"
                    placeholder=""
                    class="form-control input-md"
                    v-model="distribuidor.id_maxinivel"
                    type="text"
                  />
                </div>
                <!-- {{ updateDistribuidor.id_maxinivel }} -->
              </div>

              <div class="form-group">
                <label class="col-md-2 control-label" for="Nome"
                  >Nome Fantasia</label
                >
                <div class="col-md-12">
                  <input
                    id="nomeFant"
                    name="nomeFant"
                    placeholder=""
                    class="form-control input-md"
                    v-model="distribuidor.nome_fantasia"
                    type="text"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-2 control-label" for="Nome"
                  >Razão Social</label
                >
                <div class="col-md-12">
                  <input
                    id="social"
                    name="social"
                    placeholder=""
                    class="form-control input-md"
                    v-model="distribuidor.razao_social"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div style="" class="row">
              <div class="col">
                <div class="form-group">
                  <label class="col-md-2 control-label" for="Nome"
                    >CPF/CNPJ</label
                  >
                  <input
                    id="cpf"
                    name="cpf"
                    placeholder=""
                    class="form-control input-md"
                    v-model="distribuidor.cnpj_cpf"
                    type="text"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label class="col-md-2 control-label" for="Nome">IE</label>
                  <input
                    id="ie"
                    name="ie"
                    placeholder=""
                    class="form-control input-md"
                    v-model="distribuidor.ie"
                    type="text"
                  />
                </div>
              </div>

              <div class="w-100"></div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="col-md-2 control-label" for="Nome"
                  >Logradouro</label
                >
                <input
                  id="lagradouro"
                  name="lagradouro"
                  placeholder=""
                  class="form-control input-md"
                  type="text"
                  v-model="distribuidor.logradouro"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="col-md-2 control-label" for="Nome">Numero</label>
                <input
                  id="numero"
                  name="numero"
                  placeholder=""
                  class="form-control input-md"
                  type="text"
                  v-model="distribuidor.numero"
                />
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col">
              <div class="form-group">
                <label class="col-md-2 control-label" for="Nome">Bairro</label>
                <input
                  id="bairro"
                  name="bairro"
                  placeholder=""
                  class="form-control input-md"
                  type="text"
                  v-model="distribuidor.bairro"
                />
              </div>
            </div>

            <div class="w-100"></div>
            <div class="col">
              <div class="form-group">
                <label class="col-md-2 control-label" for="Nome">CEP</label>
                <input
                  id="cep"
                  name="cep"
                  placeholder=""
                  class="form-control input-md"
                  type="text"
                  v-model="distribuidor.cep"
                />
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-2 control-label" for="Nome">Email</label>
              <div class="col-md-12">
                <input
                  id="email"
                  name="email"
                  placeholder=""
                  class="form-control input-md"
                  type="email"
                  v-model="distribuidor.email"
                />
              </div>
            </div>

            <div class="form-group">
              <label class="col-md-2 control-label" for="Nome">Telefone</label>
              <div class="col-md-12">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  class="form-control input-md"
                  v-model="distribuidor.celular"
                  v-mask="'(##) #####-####'"
                />
              </div>
            </div>
            <!-- Submit button -->
            <br />
            <div class="container-fluid">
              <div style="" class="row">
                <div class="col">
                  <button type="submit" class="buttonSave">Salvar</button>
                </div>
                <div class="col">
                  <router-link :to="{ name: 'listadistribuidores' }"
                    ><button class="buttonCancel" role="button">
                      Voltar
                    </button></router-link
                  >
                </div>
                <div class="col"></div>
              </div>
            </div>
          </form>
          <br /><br />
        </div>
      </article>
    </div>
  </DashboardComponent>
</template>

<script>
import DashboardComponent from "../Dashboard/Dashboard.vue";
//import { mapActions, mapState } from "vuex";
import { http } from "../../../http/index";

export default {
  name: "EditDistribuidorComponent",
  components: {
    DashboardComponent,
  },
  data() {
    return {
      distribuidor: [],
      uf: "",
      cidades: [],
      payload: {
        id_distribuidor: this.$route.params.id,
      },
    };
  },
  computed: {
    //...mapState("distribuidores", ["distribuidor"]),
  },
  methods: {
    /// ...mapActions("distribuidores", ["getDistribuidor", "updateDistribuidor"]),

    salvar(distribuidor) {
      http
        .put("distribuidores", distribuidor)
        .then((response) => {
          if (response.status == 200 || response.status == 201) {
            this.$fire({
              title: "Sucesso",
              text: "Distribuidor Editado com sucesso",
              type: "success",
              timer: 5000,
            });
          }
        })
        .catch((erro) => {
          console.log(erro);
        })
        .finally(() => {
          this.$router.push({ name: "listadistribuidores" });
        });

      //this.updateDistribuidor(this.distribuidor);
      // this.$router.push({ name: "listadistribuidores" });
      // if (this.updateDistribuidor == true) {
      //   this.$router.push({ name: "listadistribuidores" });

      // }
    },

    getDadosDistribuidor() {
      http
        .post("distribuidores/id", this.payload)
        .then((response) => {
          this.distribuidor = response.data;
          console.log(this.distribuidor);
        })
        .catch((erro) => {
          console.log(erro);
        })
        .finally(() => {});
    },
  },
  mounted() {
    //this.getDistribuidor(this.payload);
  },
  created() {
    this.getDadosDistribuidor();
  },
};
</script>

<style scoped>
.content-pages {
  padding: 1px;
}

article {
  background-color: #1f1f1f;
  height: 100%;
  display: flex;
}
h1 {
  text-align: center;
}

.buttonSave {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  font-weight: bold;
  background-color: #ffce40;
  color: white;
  border: none;
}
.buttonCancel {
  font-weight: bold;
  color: white;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(67, 192, 255);
  border: none;
}
</style>