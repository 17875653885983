<template>
  <DashboardComponent>
    <div slot="slot-pages" class="content-pages">
      <div class="container fluid">
        <br />
        <h1>Editar Usuario</h1>
        <br />
        <form @submit.prevent="salvar()">
          <div class="form-group">
            <label class="col-md-2 control-label" for="Nome"
              >Usuario</label
            >
            <div class="col-md-8">
              <input
                placeholder=""
                class="form-control input-md"
                v-model="usuario.usuario"
                required=""
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <label class="col-md-2 control-label" for="Nome"
              >Nome</label
            >
            <div class="col-md-8">
              <input
               
                placeholder=""
                class="form-control input-md"
                v-model="usuario.nome"
                required=""
                type="text"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Nova Senha</label>
            <div class="col-md-8">
              <input
                type="password"
                class="form-control"
                v-model="usuario.password"
                id="adminPassword"
                placeholder="Password"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1">Confirme a Nova Senha</label>
            <div class="col-md-8">
              <input
                type="password"
                class="form-control"
                id="adminPasswordConfirm"
                placeholder="Password"
              />
            </div>
          </div>
          <br />
          <div style="" class="row">
            <div class="col">
              <button type="submit" class="buttonSave">Salvar</button>
            </div>
            <div class="col">
              <router-link :to="{ name: 'usuarios' }"
                ><button class="buttonCancel" role="button">
                  Voltar
                </button></router-link
              >
            </div>
            <div class="col"></div>
          </div>
        </form>
        <br />
      </div>
    </div>
  </DashboardComponent>
</template>


<script>
//import { mapState, mapActions } from "vuex";
import { http } from "../../../http";
import DashboardComponent from "../Dashboard/Dashboard.vue";
//import { http } from "../../../http/index";
export default {
  name: "EditUsuarioComponent",
  components: {
    DashboardComponent,
  },
  data() {
    return {
      id_admin: this.$route.params.id,

      usuario: {
        usuario: "",
        nome: "",
        password: "",
      },
    };
  },

  computed: {
    //  ...mapState("usuarios", ["usuario"]),
  },

  methods: {
    // ...mapActions("usuarios", ["getUsuario", "updateUsuario"]),

    salvar() {
      http
        .put("user/update", this.usuario)
        .then((response) => {
          if (response.status == 201 || response.status == 200) {
            this.$fire({
              title: "Sucesso",
              text: "Distribuidor editado com sucesso",
              type: "success",
              timer: 5000,
            }).then(() => {
              //console.log(r.value);
            });
          } else if (response.status == 500) {
            alert("falha ao salvar");
          } else {
            alert("falha");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$router.push({ name: "usuarios" });
        });

      // this.updateUsuario(this.usuario);
      // this.getUsuario();
      // this.$fire({
      //   title: "Sucesso",
      //   text: "Distribuidor editado com sucesso",
      //   type: "success",
      //   timer: 5000,
      // }).then((r) => {
      //   console.log(r.value);
      // });
      // this.$router.push({ name: "usuarios" });
    },

    pegadoDadosUsuario() {
      http.get(`user/${this.id_admin}`).then((response) => {
        this.usuario = response.data;
        //console.log(this.usuario);
      });
    },
  },
  mounted() {
    // this.getUsuario(this.$route.params.id);
  },
  created() {
    this.pegadoDadosUsuario();
  },
};
</script>


<style scoped>
.buttonSave {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  font-weight: bold;
  background-color: #ffce40;
  color: white;
  border: none;
}
.buttonCancel {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  font-weight: bold;
  background-color: rgb(67, 192, 255);
  color: white;
  border: none;
}
</style>